import consumer_trains_order_getTrainsReturnOrderList from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsReturnOrderList'
import Pagination from '@/component/pagination/index.vue'
import moment from "moment";
export default {
    data () {
        return {
            loading: false,
            params: {
                currentPage: 1,
                pageSize: 10,
                orderStatus: '', // 订单状态
                orderNo: '', // 订单编号
                scheduledWay: '', // 预定方式
                passengerName: '', // 乘客姓名
                total: 0
            },
            orderStatusList: [ // 1-申请退款 2-退款中 3-退票成功 4-退票失败 5- 取消退票
                {value: '', label: '全部'},
                {value: 1, label: '申请退款'},
                {value: 2, label: '退款中'},
                {value: 3, label: '退票成功'},
                {value: 4, label: '退票失败'},
                {value: 5, label: '取消退票'},
            ],
            scheduledWayList: [
                {value: '', label: '全部'},
                {value: 2, label: '因公出差'},
                {value: 1, label: '因私出行'}
            ],
            orderList: [],
            reloadTicketFun: '',
            reloadNum: 0,
            showLoading: true
        }
    },
    components: {
        Pagination
    },
    created () {},
    mounted () {

    },
    activated () {
        this.getRefundOrderList()
        this.reloadList()
    },
    deactivated() {
        clearInterval(this.reloadTicketFun)
    },
    destroyed () {
        clearInterval(this.reloadTicketFun)
    },
    watch: {},
    computed: {},
    filters: {
        filterWhatDay(val) {
            if (val === 1) {
                return '周一'
            } else if (val === 2) {
                return '周二'
            } else if (val === 3) {
                return '周三'
            } else if (val === 4) {
                return '周四'
            } else if (val === 5) {
                return '周五'
            } else if (val === 6) {
                return '周六'
            } else if (val === 7) {
                return '周日'
            } else {
                return ''
            }
        },
        filterTime(val) {
            if (val) {
                return moment(val).format('HH:mm')
            } else {
                return ''
            }
        }
    },
    methods: {
        search() {
            this.params.currentPage = 1
            this.getRefundOrderList()
        },
        //重置筛选条件
        onReset() {
            this.params = {
                currentPage: 1,
                pageSize: 10,
                orderStatus: 0, // 订单状态
                orderNo: '', // 订单编号
                scheduledWay: '', // 出行类型
                passengerName: '', // 乘客姓名
                total: 0
            }
            this.getRefundOrderList();
        },
        getRefundOrderList() {
            if (this.showLoading) {
                this.loading = true
            }
            consumer_trains_order_getTrainsReturnOrderList(this.params).then(res => {
                this.loading = false
                this.showLoading = true
                this.orderList = res.datas.list
                this.params.total = res.datas.totalCount
            }).catch(() => {
                this.loading = false
                this.showLoading = true
                this.orderList = []
                this.params.total = 0
            })
        },
        goDetail(val) {
            this.$router.push({
                name: 'admin-my-order-train-refund-detail',
                query: {
                    refundNo: val.refundNo
                }
            })
        },
        reloadList() {
            this.reloadTicketFun = setInterval(() => {
                this.reloadNum++
                if (this.reloadNum === 60) {
                    this.showLoading = false
                    this.getChangeOrderList()
                    // 页面停留超过10分钟，正在为您重新查询
                    this.reloadNum = 0
                }
            }, 1000)
        },

        //订单状态的样式
        // 订单状态 0:全部 1:-确认中 2- 待支付 3-待出票 4 -出票成功 5-出票失败 6-取消
        returnStatus(item) {
            if (item.orderStatus === 4) {
                return 'status2' // 绿色
            } else if (item.orderStatus === 5) {
                return 'status3' // 红色
            } else if (item.orderStatus === 6) {
                return 'status4' // 灰色
            } else {
                return 'status1' // 黄色
            }
        },
    }
}